import React from 'react'
import { Icon } from "@iconify/react";
import styles from './ErrorFeedback.module.css'

function ErrorFeedback(props){

    const { error } = props

    return (
        <div className={styles['error']}>
            <Icon icon="material-symbols:error" width="40" height="40"  style={{color: '#bd3b3b'}} />
            <small className={styles['error-label']}>{error ? error : 'An error occurred trying to recollect data...'}</small>
        </div>
    )

}

export default ErrorFeedback