import React from 'react'
import pp from './../../../assets/about-me/pp.png'
import styles from './ProfilePicture.module.css'

function  ProfilePicture(){
    return (
        <div className={styles['profile-picture-container']}>
            <img src={pp} className={styles['profile-picture']} alt={'Profile Picture'}/>
        </div>
    )
}

export default ProfilePicture