import React from 'react'
import cv from './../../../assets/about-me/cv.pdf'
import { Icon } from "@iconify/react";
import styles from './ContactLinks.module.css'

function ContactLinks(props){

    const { activeLang } = props

    return (
        <div className={styles['contact-links']}>
            <a href="https://www.linkedin.com/in/larl/" target={'_blank'}>
                <Icon className={styles['contact-icon']} icon="ion:logo-linkedin" />
            </a>
            <a href="https://wa.me/50433099408" target={'_blank'}>
                <Icon className={styles['contact-icon']} icon="fa6-brands:square-whatsapp" />
            </a>
            <a href={cv} className={styles['cv']} download="Luis Rodriguez - Curriculum Vitae">
                <button type={'button'} className={styles['cv-button']}>
                    <Icon icon="eva:download-fill"/>
                    <p>{activeLang ? 'Get Curriculum' : 'Obtener Curriculum'}</p>
                    <span></span>
                </button>
            </a>
        </div>
    )
}

export default ContactLinks