import React, { useContext } from 'react'
import { NavLink } from "react-router-dom";
import { LangContext } from "../../../store/lang-context";
import styles from './Menu.module.css'

function Menu(){

    const { activeLang } = useContext(LangContext)

    return (
        <div className={styles['menu']}>
            <NavLink to={'/'} className={styles['menu-link']}>
                {activeLang ? 'About Me' : 'Sobre Mi'}
            </NavLink>
            <NavLink to={'/skills'} className={styles['menu-link']}>
                {activeLang ? 'Skills' : 'Habilidades'}
            </NavLink>
            <NavLink to={'/credentials'} className={styles['menu-link']}>
                {activeLang ? 'Credentials' : 'Credenciales'}
            </NavLink>
            <NavLink to={'/projects'} className={styles['menu-link']}>
                {activeLang ? 'Projects' : 'Proyectos'}
            </NavLink>
            <NavLink to={'/contact-me'} className={styles['menu-link']}>
                {activeLang ? 'Contact Me' : 'Contactame'}
            </NavLink>
        </div>
    )
}

export default Menu