import React, { createContext, useState } from 'react'

// Language Context
export const LangContext = createContext({
    activeLang: true,
    activateLangHandler: () => {},
})

function LangContextProvider(props){

    // Props
    const { children } = props

    // State Management
    const [ activeLang, setActiveLang ] = useState(true)

    // Event Handlers
    const toggleLangHandler = () => {
        setActiveLang((prevState) => {
            return !prevState
        })
    }

    return (
        <LangContext.Provider value={{activeLang: activeLang, toggleLangHandler: toggleLangHandler}}>
            { children }
        </LangContext.Provider>
    )
}

export default LangContextProvider
