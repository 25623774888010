import React, { useContext } from 'react'
import { LangContext } from "../../store/lang-context";
import Title from "../../components/UI/Content/Title";
import OutstandingTitle from "../../components/UI/Content/OutstandingTitle";
import Information from "../../components/UI/Content/Information";
import ContactMeForm from "../../components/ContactMe/ContactMeForm/ContactMeForm";
import styles from './ContactMe.module.css'

function ContactMe(){

    // Context
    const { activeLang } = useContext(LangContext)

    return (
        <div className={styles['contact-me']}>
            <div className={styles['contact-me-info']}>
                <Title activeLang={activeLang}
                       engText={'You want to know more about me?'}
                       spaText={'¿Quieres saber mas de mi?'}/>
                <OutstandingTitle activeLang={activeLang}
                                  engText={'Send me a message:)'}
                                  spaText={'Enviame un mensaje:)'}/>
                <Information activeLang={activeLang}
                     engText={'Effective communication is key to building relationships and fostering collaboration. Reaching out is an important step in sharing ideas, asking questions, or discussing potential opportunities. I welcome inquiries and am eager to connect with others who share a passion for innovation and growth. Whether you have feedback, questions, or just want to chat, feel free to get in touch!\n'}
                     spaText={'La comunicación efectiva es clave para construir relaciones y fomentar la colaboración. Contactar es un paso importante para compartir ideas, hacer preguntas o discutir oportunidades potenciales. Aprecio las consultas y estoy ansioso por conectarme con otros que comparten una pasión por la innovación y el crecimiento. Si tienes comentarios, preguntas o solo deseas charlar, ¡no dudes en ponerte en contacto!\n'}/>
            </div>
            <div className={styles['contact-me-form']}>
                <ContactMeForm/>
            </div>
        </div>
    )
}

export default ContactMe