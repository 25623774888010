import React, { useContext } from 'react'
import { LangContext } from "../../../../store/lang-context";
import styles from './ProjectFilter.module.css'

function ProjectFilter(props){

    // Props
    const { projectTypes, activeType, toggleActiveTypeHandler } = props

    // Context
    const { activeLang } = useContext(LangContext)

    // Functions
    const setClasses = (projectTypeId) => {
        return projectTypeId === activeType ?
               `${styles['filter-button-active']} ${styles['filter-button']}` :
               `${styles['filter-button']}`
    }

    // Event Handlers
    const filterToggleHandler = (event) => {
        const type = event.target.getAttribute('data-value')
        toggleActiveTypeHandler(parseInt(type))
    }

    return (
        <div className={styles['project-filter']}>
            {projectTypes && projectTypes.map((projectType, index) => {
                return (
                    <button key={index} className={setClasses(projectType.id)}
                         onClick={filterToggleHandler}
                         data-value={projectType.id}>
                        {activeLang ? projectType.type : projectType.type_spanish}
                    </button>
                )
            })}
        </div>
    )

}

export default ProjectFilter