import React from 'react'

function Information(props){

    // Props
    const { activeLang, engText, spaText } = props

    return (
        <p>{activeLang ? engText : spaText}</p>
    )
}

export default Information