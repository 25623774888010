import React, { useState } from 'react'
import SkillCardHeader from './SkillCardHeader/SkillCardHeader'
import SkillGrouping from "./SkillGrouping/SkillGrouping";
import SkillExpertise from "./SkillExpertise/SkillExpertise";
import styles from  './SkillCard.module.css'

function SkillCard(props){

    // Props
    const { skillGroup, activeCategory, toggleCategoryHandler } = props
    const { id: categoryId, name, name_spanish, total_skills, subcategories, skills } = skillGroup

    // States
    const [ cardExpanded, setCardExpanded ] = useState(false)

    // Classes
    const classes = `${styles['skill-card']} 
                             ${cardExpanded && styles['card-expanded']} 
                             ${activeCategory !== null && activeCategory !== categoryId && styles['card-hidden']}`

    // Event Handlers
    const cardClickHandler = () => {
        toggleCategoryHandler(categoryId)
        setCardExpanded((prevState) => {
            return !prevState
        })
    }

    return (
        <div className={classes} onClick={cardClickHandler}>
            <SkillCardHeader category={[name, name_spanish]} counter={total_skills} expanded={cardExpanded}/>
            {cardExpanded && subcategories && subcategories.map((category, index) => {
               return <SkillGrouping key={index} category={category}/>
            })}
            {cardExpanded && skills && skills.map((skill, index) => {
                return <SkillExpertise key={index} skill={skill}/>
            })}
        </div>
    )

}

export default SkillCard