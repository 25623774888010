import React, { useContext } from 'react'
import { LangContext}  from "../../../../store/lang-context";
import styles from './SkillCardHeader.module.css'

function SkillCardHeader(props){

    // Props
    const { expanded, category, counter } = props

    // Context
    const { activeLang } = useContext(LangContext)

    return (
        <div className={styles['card-header']}>
            <div className={styles['card-label']}>
                <h2>{activeLang ? category[0] : category[1]}</h2>
                {!expanded && <small>{activeLang ? 'Please click to expand this card' : 'Haz click para extender la tarjeta'}</small>}
                {expanded && <small>{activeLang ? 'Click in order close this card' : 'Haz click para cerrar la tarjeta'}</small>}
            </div>
            {!expanded &&
                <div className={styles['card-counter']}>
                    <h2>{counter}</h2>
                </div>
            }
        </div>
    )

}

export default SkillCardHeader