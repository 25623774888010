import React, {useState, useCallback} from 'react'

function useHttp(){

    const [ isLoading, setIsLoading ] = useState(false)
    const [ success, setSuccess ] = useState(false)
    const [ error, setError ] = useState(false)
    const [ data, setData ] = useState(null)

    const sendRequest = useCallback(async (requestConfig) => {

        setIsLoading(true)
        setSuccess(false)
        setError(false)
        setData(null)

        const { url, method = 'GET', body } = requestConfig
        const requestBody = {
            method: method,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        }

        try {
            const response = await fetch(`https://www.api.lrdev.sealena.com/${url}`, requestBody)
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Request failed!');
            }
            const data = await response.json()
            setSuccess(true)
            setData(data.data)
        }catch (e) {
            setSuccess(false)
            setError(e.message)
        }finally {
            setIsLoading(false)
        }

    }, [])

    return { isLoading, success, error, data, sendRequest }

}

export default useHttp