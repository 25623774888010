import React, { useState, useEffect, useContext } from 'react'
import { LangContext } from "../../store/lang-context";
import useHttp from "../../hooks/useHttp";
import Title from "../../components/UI/Content/Title";
import OutstandingTitle from "../../components/UI/Content/OutstandingTitle";
import Information from "../../components/UI/Content/Information";
import CredentialCard from "../../components/Credentials/CredentialCard/CredentialCard";
import Loader from "../../components/UI/Loader/Loader";
import ErrorFeedback from "../../components/UI/ErrorFeedback/ErrorFeedback";
import styles from './Credentials.module.css'

function Credentials(){

    // Context
    const { activeLang } = useContext(LangContext)

    // HTTP Hook
    const { isLoading, success, error, data, sendRequest } = useHttp()

    // Effects
    useEffect(() => {
        sendRequest({url: 'credentials/'})
    }, [])

    // State Managemenet
    const [ activeCategory, setActiveCategory ] = useState(null)

    // Event Handlers
    const toggleCategoryHandler = (categoryId) => {
        setActiveCategory((prevState) => {
            return prevState === null ? categoryId : null
        })
    }

    return(
        <div className={styles['credentials']}>
            <div className={styles['credentials-showcase']}>
                {data && data.credentials.map((credentialGroup, index) => {
                        return <CredentialCard key={index}
                                              credentialGroup={credentialGroup}
                                              activeCategory={activeCategory}
                                              toggleCategoryHandler={toggleCategoryHandler}/>
                })}
            </div>
            <div className={styles['credentials-info']}>
                <Title activeLang={activeLang} engText={'My Journey'} spaText={'Mi Aventura'}/>
                <OutstandingTitle activeLang={activeLang} engText={"How I Learned It"} spaText={'Como Lo Aprendi'}/>
                <Information activeLang={activeLang}
                             engText={"Credentials are essential for establishing expertise and credibility in any field. They demonstrate a commitment to professional development and the acquisition of skills. Certifications and courses showcase the ability to adapt to new challenges and technologies, ensuring competitiveness in a rapidly evolving industry. A strong educational foundation and practical experience enhance the ability to deliver effective solutions.\n"}
                             spaText={"Las credenciales son esenciales para establecer la experiencia y la credibilidad en cualquier campo. Demuestran un compromiso con el desarrollo profesional y la adquisición de habilidades. Las certificaciones y cursos muestran la capacidad de adaptarse a nuevos desafíos y tecnologías, asegurando la competitividad en una industria en rápida evolución. Una sólida base educativa y la experiencia práctica mejoran la capacidad de ofrecer soluciones efectivas.\n"}/>
            </div>
            <div className={styles['credentials-showcase-mobile']}>
                {isLoading && <Loader loadingText={'Collecting Credentials'}/>}
                {error && <ErrorFeedback error={error}/>}
                {success && data && data.credentials.map((credentialGroup, index) => {
                    return <CredentialCard key={index}
                                          credentialGroup={credentialGroup}
                                          activeCategory={activeCategory}
                                          toggleCategoryHandler={toggleCategoryHandler}/>
                })}
            </div>
        </div>
    )
}

export default Credentials