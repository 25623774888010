import React from 'react'

function OutstandingTitle(props){

    // Props
    const { activeLang, engText, spaText } = props

    return (
        <h1>{activeLang ? engText : spaText}</h1>
    )
}

export default OutstandingTitle