import React, { Fragment } from 'react'
import ProjectCard from "./ProjectCard/ProjectCard";
import styles from './ProjectGallery.module.css'

function ProjectGallery(props) {

    // Props
    const { projects, activeType, displayProjectHandler } = props

    // Filter projects based on activeType
    const filteredProjects = activeType ? projects.filter(project => project.type.id === activeType) : projects;

    return (
        <Fragment>
            <div className={styles['gallery']}>
                {filteredProjects && filteredProjects.map((project, index) => {
                    return (
                        <Fragment key={index}>
                            <ProjectCard project={project} displayProjectHandler={displayProjectHandler}/>
                        </Fragment>
                    )
                })}
            </div>
        </Fragment>
    )

}

export default ProjectGallery