import React, { useState, useEffect, useContext } from 'react'
import { LangContext } from "../../store/lang-context";
import useHttp from "../../hooks/useHttp";
import Title from "../../components/UI/Content/Title";
import OutstandingTitle from "../../components/UI/Content/OutstandingTitle";
import Information from "../../components/UI/Content/Information";
import SkillCard from "../../components/Skills/SkillCard/SkillCard";
import Loader from "../../components/UI/Loader/Loader";
import ErrorFeedback from "../../components/UI/ErrorFeedback/ErrorFeedback";
import styles from './Skills.module.css'

function Skills(){

    // Context
    const { activeLang } = useContext(LangContext)

    // HTTP Hook
    const { isLoading, success, error, data, sendRequest } = useHttp()

    // Effects
    useEffect(() => {
        sendRequest({url: 'skills/'})
    }, [])

    // State Managemenet
    const [ activeCategory, setActiveCategory ] = useState(null)

    // Event Handlers
    const toggleCategoryHandler = (categoryId) => {
        setActiveCategory((prevState) => {
            return prevState === null ? categoryId : null
        })
    }

    return(
        <div className={styles['skills']}>
            <div className={styles['skills-info']}>
                <Title activeLang={activeLang} engText={'My Toolbox'} spaText={'Mi Caja de Herramientas'}/>
                <OutstandingTitle activeLang={activeLang} engText={"What I'm Good At"} spaText={'En Que Soy Bueno'}/>
                <Information activeLang={activeLang}
                             engText={"In today’s dynamic tech landscape, skills are crucial for success. The ability to adapt to new technologies and methodologies is essential for effective problem-solving and innovation. Collaboration and clear communication are key to working within diverse teams, driving projects forward, and achieving common goals. A commitment to continuous learning and growth ensures that professionals remain competitive and can deliver high-quality solutions that meet evolving user needs.\n"}
                             spaText={"En el dinámico panorama tecnológico actual, las habilidades son cruciales para el éxito. La capacidad de adaptarse a nuevas tecnologías y metodologías es esencial para resolver problemas de manera efectiva. La colaboración y la comunicación clara son clave para impulsar proyectos y alcanzar objetivos comunes. Un compromiso con el crecimiento asegura que los profesionales puedan ofrecer soluciones de alta calidad que satisfagan las necesidades cambiantes de los usuarios.\n"}/>
            </div>
            <div className={styles['skills-showcase']}>
                {isLoading && <Loader loadingText={'Collecting Skills'}/>}
                {error && <ErrorFeedback error={error}/>}
                {success && data && data.skills.map((skillGroup, index) => {
                    return <SkillCard key={index}
                                      skillGroup={skillGroup}
                                      activeCategory={activeCategory}
                                      toggleCategoryHandler={toggleCategoryHandler}/>
                })}
            </div>
        </div>
    )
}

export default Skills