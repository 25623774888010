import React, { Fragment, useState, useEffect, useReducer, useRef, useContext } from 'react'
import { LangContext } from "../../../store/lang-context";
import useHttp from "../../../hooks/useHttp";
import Loader from "../../UI/Loader/Loader";
import SuccessMailFeedback from "../SuccessMailFeedback/SuccessMailFeedback";
import ErrorFeedback from "../../UI/ErrorFeedback/ErrorFeedback";
import styles from './ContactMeForm.module.css'

const initialState = {
    firstName: null, firstNameTouched: null, firstNameValid: null,
    lastName: null, lastNameTouched: null, lastNameValid: null,
    email: null, emailTouched: null, emailValid: null,
    message: null, messageTouched: null, messageValid: null
}

function contactFormReducer(prevState, action){

    let updatedState;

    switch (action.type){
        case 'FN_TOUCHED':
            updatedState = {...prevState, firstNameTouched: true, firstNameValid: prevState.firstName !== null}
            break
        case 'FN_INPUT':
            updatedState = {...prevState, firstName: action.value, firstNameTouched: true, firstNameValid: action.value.length > 0}
            break
        case 'LN_TOUCHED':
            updatedState = {...prevState, lastNameTouched: true, lastNameValid: prevState.lastName !== null}
            break
        case 'LN_INPUT':
            updatedState = {...prevState, lastName: action.value, lastNameTouched: true, lastNameValid: action.value.length > 0}
            break
        case 'EMAIL_TOUCHED':
            updatedState = {...prevState, emailTouched: true, emailValid: prevState.email !== null}
            break
        case 'EMAIL_INPUT':
            updatedState = {...prevState, email: action.value, emailTouched: true, emailValid: action.value.includes('@')}
            break
        case 'MSG_TOUCHED':
            updatedState = {...prevState, messageTouched: true, messageValid: prevState.message !== null}
            break
        case 'MSG_INPUT':
            updatedState = {...prevState, message: action.value, messageTouched: true, messageValid: action.value.length > 0}
            break
        default:
            return prevState
    }

    return updatedState

}


function ContactMeForm(){

    // State
    const [formValid, setFormValid] = useState(false)
    const [ currentFormState, formStateDispatcher ] = useReducer(contactFormReducer, initialState)
    const { firstName, firstNameTouched, firstNameValid, lastName, lastNameTouched, lastNameValid,
            email, emailTouched, emailValid, message, messageTouched, messageValid } = currentFormState

    // HTTP Request utilities
    const { isLoading, success, error, data, sendRequest } = useHttp()

    // References
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const emailRef = useRef()
    const messageRef = useRef()

    // Context
    const { activeLang} = useContext(LangContext)

    // Classes
    const invalidClass = `${styles['invalid-input']}`

    // Effects
    useEffect(() => {
        setFormValid(firstNameValid && lastNameValid && emailValid && messageValid)
    }, [firstNameValid, lastNameValid, emailValid, messageValid]);

    // Event Handlers
    const inputFocusHandler = (event) => {
        const target = event.target.getAttribute('data-target')
        formStateDispatcher({type: `${target}_TOUCHED`})
    }

    const inputChangeHandler = (event) => {
        const target = event.target.getAttribute('data-target')
        const value = event.target.value
        formStateDispatcher({type: `${target}_INPUT`, value: value})
    }

    const submitHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()

        if (!firstNameValid){
            firstNameRef.current.focus()
        }else if (!lastNameValid){
            lastNameRef.current.focus()
        }else if (!emailValid){
            emailRef.current.focus()
        }else if (!messageValid){
            messageRef.current.focus()
        }else{
            const requestBody = {first_name: firstName, last_name: lastName, from_email: email, message: message}
            sendRequest({url: 'mailing/', method: 'POST', body: requestBody})
        }
    }

    return (
        <Fragment>
            {!isLoading && !error && !success &&
                <form className={styles['contact-me-form']} onSubmit={submitHandler}>
                    <label htmlFor={'firstName'}>{activeLang ? 'First Name' : 'Nombre'}</label>
                    <input id={'firstName'}
                           type="text"
                           className={!(firstNameValid === firstNameTouched) && invalidClass}
                           value={firstName}
                           placeholder={activeLang ? 'Enter your first name' : 'Ingrese su nombre'}
                           ref={firstNameRef}
                           onFocus={inputFocusHandler}
                           onChange={inputChangeHandler}
                           data-target={'FN'}/>
                    <label htmlFor={'lastName'}>{activeLang ? 'Last Name' : 'Apellido'}</label>
                    <input id={'lastName'}
                           type="text"
                           className={!(lastNameValid === lastNameTouched) && invalidClass}
                           value={lastName}
                           placeholder={activeLang ? 'Enter your last name' : 'Ingrese su apellido'}
                           ref={lastNameRef}
                           onFocus={inputFocusHandler}
                           onChange={inputChangeHandler}
                           data-target={'LN'}/>
                    <label htmlFor={'email'}>Email</label>
                    <input id={'email'}
                           type="email"
                           className={!(emailValid === emailTouched) && invalidClass}
                           value={email}
                           placeholder={activeLang ? 'Enter your email' : 'Ingrese su email'}
                           ref={emailRef}
                           onFocus={inputFocusHandler}
                           onChange={inputChangeHandler}
                           data-target={'EMAIL'}/>
                    <label id={'message'} htmlFor={'message'}>{activeLang ? 'Message' : 'Mensaje'}</label>
                    <textarea name={'message'}
                              id={'message'}
                              cols="30"
                              rows="10"
                              className={!(messageValid === messageTouched) && invalidClass}
                              value={message}
                              placeholder={activeLang ? 'Enter your message' : 'Ingrese su mensaje'}
                              ref={messageRef}
                              onFocus={inputFocusHandler}
                              onChange={inputChangeHandler}
                              data-target={'MSG'}/>
                    <button type={'submit'} className={styles['contact-me-submit']}>
                        {activeLang ? 'Send Message' : 'Enviar Mensaje'}
                    </button>
                </form>}
            {isLoading && <Loader loadingText={'Sending Message'}/>}
            {error && <ErrorFeedback error={error}/>}
            {success && <SuccessMailFeedback/>}
        </Fragment>
    )
}

export default ContactMeForm