import React from 'react'
import styles from './Modal.module.css'

function Modal(props){

    // Props
    const { children, menuDisplay } = props

    // Classes
    const classes = `${styles['modal']} ${menuDisplay && styles['modal-menu']}`

    return (
        <div className={classes}>
            { children }
        </div>
    )
}

export default Modal