import React from 'react'
import { Routes, Route } from "react-router-dom";
import AboutMe from './pages/AboutMe/AboutMe'
import Skills from "./pages/Skills/Skills";
import Credentials from "./pages/Credentials/Credentials";
import Projects from "./pages/Projects/Projects";
import ContactMe from "./pages/ContactMe/ContactMe";
import NavBar from "./components/UI/NavBar/NavBar";

function App() {
    return (
        <div className="App">
            <NavBar/>
            <Routes>
                <Route path={'/'} element={<AboutMe/>}/>
                <Route path={'/skills'} element={<Skills/>}/>
                <Route path={'/credentials'} element={<Credentials/>}/>
                <Route path={'/projects'} element={<Projects/>}/>
                <Route path={'/contact-me'} element={<ContactMe/>}/>
            </Routes>
        </div>
    );
}

export default App;
