import React, { useState, useEffect, useContext } from 'react'
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { LangContext } from "../../../store/lang-context";
import Modal from './../Modal/Modal'
import Menu from "../Menu/Menu";
import styles from './NavBar.module.css'

function NavBar(){

    // Context
    const { activeLang, toggleLangHandler } = useContext(LangContext)
    const location = useLocation()

    // State Management
    const [ menuActive, setMenuActive ] = useState(false)
    const [ activeSection, setActiveSection ] = useState('About Me')

    // Event Handlers
    const toggleMenuHandler = () => {
        setMenuActive((prevState) => {
            return !prevState
        })
    }

    // Effects
    useEffect(() => {
        setMenuActive(false)
        switch(location.pathname){
            case '/':
                setActiveSection(activeLang ? 'About Me' : 'Sobre Mi')
                return
            case '/skills':
                setActiveSection(activeLang ? 'Skills' : 'Habilidades')
                return
            case '/credentials':
                setActiveSection(activeLang ? 'Credentials' : 'Credenciales')
                return
            case '/projects':
                setActiveSection(activeLang ? 'Projects' : 'Proyectos')
                return
            case '/contact-me':
                setActiveSection(activeLang ? 'Contact Me' : 'Contactame')
                return
            default:
                return
        }

    }, [location, activeLang])


    return (
        <div className={styles['navbar']}>
            {menuActive ?
            <Icon className={styles['navbar-icon']}
                  onClick={toggleMenuHandler}
                  icon="gg:close" /> :
            <Icon className={styles['navbar-icon']}
                  onClick={toggleMenuHandler}
                  icon="icon-park-outline:hamburger-button" />}
            <h2>{menuActive ? 'Menu' : activeSection}</h2>
            <p className={styles['lang-toggler']} onClick={toggleLangHandler}>
                <span className={`${activeLang && styles['lang-toggle-active']}`}>EN</span>/
                <span className={`${!activeLang && styles['lang-toggle-active']}`}>ES</span>
            </p>
            {menuActive &&
                createPortal(
                    <Modal menuDisplay={true}>
                        <Menu/>
                    </Modal>,
                    document.querySelector('#modal')
                )
            }
        </div>
    )
}

export default NavBar