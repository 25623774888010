import React from 'react'
import { Icon } from "@iconify/react";
import styles from './SkillTile.module.css'

function SkillTile(props){

    // Props
    const { skill } = props
    const { name, logo } = skill

    return (
        <div className={styles['skill-tile']}>
            <Icon icon={logo} className={styles['skill-icon']}/>
            <p>{name}</p>
            <span></span>
        </div>
    )
}

export default SkillTile