import React from 'react'
import styles from './Loader.module.css'

function Loader(props){

    const { loadingText } = props

    return (
        <div className={styles['loader']}>
            <div className={styles['loader-spinner']}></div>
            <small className={styles['loader-text']}>{loadingText ? `${loadingText}...` : 'Loading Data...'}</small>
        </div>
    )
}

export default Loader