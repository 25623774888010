import React, { useState, useEffect } from 'react'
import ProjectFilter from "./ProjectFilter/ProjectFilter";
import ProjectGallery from "./ProjectGallery/ProjectGallery";
import ProjectViewer from "./ProjectViewer/ProjectViewer";
import Modal from "../../UI/Modal/Modal";
import styles from './ProjectShowroom.module.css'

function ProjectShowroom(props){

    // Props
    const { projectsData } = props
    const { project_types: projectTypes, projects } = projectsData

    // States
    const [ activeType, setActiveType ] = useState(null)
    const [ displayedProject, setDisplayedProject ] = useState(null)

    // Event Handlers
    const toggleActiveTypeHandler = (type) => {
        setActiveType((prevState) => {
            return prevState === type ? null : type
        })
    }

    const displayProjectHandler = (project = null) => {
        setDisplayedProject((prevState) => {
            return prevState ? null : project
        })
    }

    return (
        <div className={styles['showroom']}>
            <ProjectFilter projectTypes={projectTypes}
                           activeType={activeType}
                           toggleActiveTypeHandler={toggleActiveTypeHandler}/>
            <ProjectGallery projects={projects} activeType={activeType} displayProjectHandler={displayProjectHandler}/>
            {displayedProject &&
                <Modal>
                    <ProjectViewer project={displayedProject} displayProjectHandler={displayProjectHandler}/>
                </Modal>
            }
        </div>
    )

}

export default ProjectShowroom