import React, { useContext } from 'react'
import { LangContext } from "../../../../store/lang-context";
import styles from './SkillCategoryHeader.module.css'

function SkillCategoryHeader(props){

    // Props
    const { category } = props

    // Context
    const { activeLang } = useContext(LangContext)

    return (
        <div className={styles['subcategory-header']}>
            <small className={styles['subcategory-title']}>
                {activeLang ? category[0] : category[1]}
            </small>
        </div>
    )
}

export default SkillCategoryHeader