import React from 'react'
import CredentialCategoryHeader from "../CredentialCategoryHeader/CredentialCategoryHeader";
import CredentialVerification from "../CredentialVerification/CredentialVerification";
import styles from './CredentialGrouping.module.css'

function CredentialGrouping(props){

    // Props
    const { category } = props
    const { name, name_spanish, credentials } = category


    return (
        <div className={styles['credential-grouping']}>
            <CredentialCategoryHeader category={[name, name_spanish]}/>
            {credentials && credentials.map((credential, index) => {
                return <CredentialVerification key={index} credential={credential}/>
            })}
        </div>
    )
}

export default CredentialGrouping