import React, { useContext } from 'react'
import { LangContext } from "../../../../../store/lang-context";
import { Icon } from "@iconify/react";
import styles from './CollaborationBadge.module.css'

function CollaborationBadge(){

    // Collaboration
    const { activeLang } = useContext(LangContext)

    return (
        <div className={styles['collaboration-badge']}>
            <Icon icon="heroicons:check-badge-solid" width="24" height="24"  style={{color: '#466FAD'}} />
            <p>Collaboration</p>
        </div>
    )

}

export default CollaborationBadge