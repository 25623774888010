import React from 'react'

function Title(props){

    // Props
    const { activeLang, engText, spaText } = props

    return (
        <h2>{activeLang ? engText : spaText}</h2>
    )
}

export default Title