import React, { Fragment, useState, useContext } from 'react'
import { createPortal } from 'react-dom'
import { LangContext } from "../../../../store/lang-context";
import { Icon } from "@iconify/react";
import DiplomaViewer from "../../DiplomaViewer/DiplomaViewer";
import Modal from "../../../UI/Modal/Modal";
import styles from './CredentialVerification.module.css'

function CredentialVerification(props){

    // Props
    const { credential } = props
    const { name, name_spanish, diploma, certificate_url, logo } = credential

    // Context
    const { activeLang } = useContext(LangContext)

    // State
    const [ diplomaActive, setDiplomaActive ] = useState(false)

    // Event Handlers
     const showDiplomaClickHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setDiplomaActive(true)
    }

    const closeDiplomaViewerClickHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setDiplomaActive(false)
    }

    const verifyCredentialClickHandler = (event) => {
        event.preventDefault()
        event.stopPropagation()
        window.open(certificate_url, '_blank')
    }

    return (
        <Fragment>
            <div className={styles['credential']}>
                <div className={styles['credential-header']}>
                    <p className={styles['credential-title']}>{activeLang ? name : name_spanish}</p>
                    <Icon icon={logo}/>
                </div>
                <div className={styles['credential-verification']}>
                    {diploma &&
                        <button type={'button'} onClick={showDiplomaClickHandler}>Show Diploma</button>
                    }
                    {certificate_url &&
                        <button type={'button'} onClick={verifyCredentialClickHandler}>Verify Credential</button>
                    }
                </div>
            </div>
            {diplomaActive && createPortal(
                <Modal>
                    <DiplomaViewer diploma={`http://192.168.2.105:8000${diploma}`} closeDiplomaViewerHandler={closeDiplomaViewerClickHandler}/>
                </Modal>,
                document.querySelector('#modal')
            )}
        </Fragment>
    )


}

export default CredentialVerification