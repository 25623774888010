import React from 'react'
import styles from './DiplomaViewer.module.css'

function DiplomaViewer(props){

    const { diploma, closeDiplomaViewerHandler } = props

    return (
        <div className={styles['diploma-viewer']} onClick={closeDiplomaViewerHandler}>
            <img className={styles['diploma']} src={diploma} alt={'Diploma'}/>
        </div>
    )
}

export default DiplomaViewer