import React from 'react'
import SkillCategoryHeader from "../SkillCategoryHeader/SkillCategoryHeader";
import SkillExpertise from "../SkillExpertise/SkillExpertise";
import styles from './SkillGrouping.module.css'

function SkillGrouping(props){

    // Props
    const { category } = props
    const { name, name_spanish, skills } = category


    return (
        <div className={styles['skill-grouping']}>
            <SkillCategoryHeader category={[name, name_spanish]}/>
            {skills && skills.map((skill, index) => {
                return <SkillExpertise key={index} skill={skill}/>
            })}
        </div>
    )
}

export default SkillGrouping