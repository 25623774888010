import React, { useContext } from 'react'
import { Icon } from "@iconify/react";
import styles from './SkillExpertise.module.css'
import {LangContext} from "../../../../store/lang-context";

function SkillExpertise(props){

    // Props
    const { skill } = props
    const { name, name_spanish, proficiency, logo } = skill

    // Context
    const { activeLang } = useContext(LangContext)

    return (
        <div className={styles['skill']}>
            <div className={styles['skill-header']}>
                <p>{activeLang ? name : name_spanish}</p>
                <Icon icon={logo}/>
            </div>
            <div className={styles['expertise-bar']}>
                <div className={styles['expertise-bar-container']}>
                    <div style={{maxWidth: `${proficiency.percentage}%`}} className={styles['expertise-level-bar']}></div>
                </div>
            </div>
            <div className={styles['expertise-level']}>
                <small className={styles['level-label']}>0%</small>
                <small className={styles['level-label']}>100%</small>
            </div>
        </div>
    )


}

export default SkillExpertise