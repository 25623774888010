import React, { useContext } from 'react'
import { LangContext } from "../../../store/lang-context";
import { Icon } from "@iconify/react";
import styles from './SuccessMailFeedback.module.css'

function SuccessMailFeedback(props){

    const { activeLang } = useContext(LangContext)

    return (
        <div className={styles['success-mail']}>
            <Icon icon="ep:success-filled" width="40" height="40" style={{color: '#62ae47'}} />
            <small className={styles['success-label']}>{activeLang ? 'Email sent successfully, thanks!' : '¡Mensaje enviado exitosamente, gracias!'}</small>
        </div>
    )

}

export default SuccessMailFeedback