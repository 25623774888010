import React, { useContext } from 'react'
import ProfilePicture from "../../components/AboutMe/ProfilePicture/ProfilePicture";
import Title from "../../components/UI/Content/Title";
import OutstandingTitle from "../../components/UI/Content/OutstandingTitle";
import Information from "../../components/UI/Content/Information";
import ContactLinks from "../../components/AboutMe/ContactLinks/ContactLinks";
import { LangContext } from "../../store/lang-context";
import styles from './AboutMe.module.css'

function AboutMe(){

    // Context
    const { activeLang } = useContext(LangContext)

    return (
        <div className={styles['about-me']}>
            <div className={styles['about-me-picture']}>
                <ProfilePicture/>
            </div>
            <div className={styles['about-me-info']}>
                <Title activeLang={activeLang}
                       engText={'Who Am I?'}
                       spaText={'¿Quien Soy?'}/>
                <OutstandingTitle activeLang={activeLang}
                                  engText={'Hello, my name is Luis Rodriguez'}
                                  spaText={'Hola, mi nombre es Luis Rodriguez'}/>
                <Information activeLang={activeLang}
                             engText={'An enthusiastic individual passionate about learning and growth. I enjoy exploring new skills and taking on challenges. As a Full Stack Web Developer, my toolbox is composed of Python/Django as backend technologies and HTML/CSS/JavaScript/React for frontend. My attention to detail and commitment to high-quality web applications set me apart. I strive to stay updated with industry trends, driven by collaboration and innovation.\n'}
                             spaText={'Una persona entusiasta con pasión por el aprendizaje y el crecimiento. Me encanta explorar nuevas habilidades y asumir desafíos. Como desarrollador web Full Stack, me especializo en Python/Django para el backend y HTML/CSS/JavaScript/React para el frontend. Mi atención al detalle y compromiso con aplicaciones web de alta calidad me distinguen. Me esfuerzo por mantenerme al tanto de las tendencias de la industria, guiado por la colaboración y la innovación\n'}/>
                <ContactLinks activeLang={activeLang}/>
            </div>
        </div>
    )
}

export default AboutMe